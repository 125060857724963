import type { Card, Cards } from '../components/CardTopUp';

export function accountHasBlockedCard(cards: Cards): Card | undefined {
  const anyActiveOrInactive = cards.some(
    card => card.state === 'Active' || card.state === 'NotActivated',
  );

  // if there is any active or non-activated cards, don't say it's blocked
  if (anyActiveOrInactive) {
    return undefined;
  }

  return cards.find(
    card => card.state === 'Blocked' || card.state === 'FraudBlocked',
  );
}

export function accountHasNonActiveCard(cards: Cards): Card | undefined {
  const hasNonExpiredCard = cards.some(
    card => !isCardExpired(card.expirationYear, card.expirationMonth),
  );

  // If the user has a card that isn't expired, we shouldn't show the expired card
  if (hasNonExpiredCard) {
    return undefined;
  }

  return cards.find(card =>
    isCardExpired(card.expirationYear, card.expirationMonth),
  );
}

export function isCardExpired(
  expirationYear: number,
  expirationMonth: number,
): boolean {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;

  return (
    expirationYear < currentYear ||
    (expirationYear === currentYear && expirationMonth < currentMonth)
  );
}
